import React from 'react'
import styled from 'styled-components'

const CenterContent = styled.div`
  max-width: ${props => props.theme.containerWidths.small};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .section-label {
    margin-top: 40px;
    margin-bottom: 10px;
  }
`
const SubscribeToCalendar = () => {
  const calendarSubscriptionLink =
    '//calendar.google.com/calendar/render?cid=bWFza2NhcmFiZWF1dHkuY29tXzZ1NXVxZzRvZGs0ZzJmN3VjMTZtamlqcDhjQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'

  return (
    <>
      <CenterContent className="section">
        <p className="headline--intro">
          To sync all Seint Artists events to your calendar, subscribe to our
          Google Calendar.
        </p>
        <a
          className="btn--pill"
          href={calendarSubscriptionLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Subscribe
        </a>
        <h3 className="section-label">iOS Devices</h3>
        <p>
          If you use the Apple Calendar app,{' '}
          <a
            href="//calendar.google.com/calendar/syncselect"
            target="_blank"
            rel="noopener noreferrer"
          >
            go to your Google Calendar sync settings
          </a>{' '}
          and check ‘Seint Artists’ then tap ‘Save’ to enable syncing.
        </p>
      </CenterContent>
    </>
  )
}

export default SubscribeToCalendar
